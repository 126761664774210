<template>
    <div>
        <Contato/>
        <div class="legenda-contato">
            <h1 class="H1">Nos envie sua mensagem!</h1>
            <h2 class="H2">Dúvidas, Vendas, Sugestões ou Reclamações</h2>
        </div>
        <div class="formulario"> 
            <form ref="form" id="contact-form" class="conteudo row" action="/contato" method="" @submit.prevent="sendEmail" >
                <div class="mb-2 col-md-6 label">
                    <label for="nome-form" class="form-name">Nome</label>
                    <input type="text" class="form-control sombra" v-model.trim="contacts.nome" id="nome-form" name="name"
                         placeholder="Digite seu nome " required pattern="[A-Za-z]{}" title="Utilize apenas letras!">
        
                </div>
                <div class="mb-2 col-md-6 label">
                    <label for="surname-form" class="form-surname">Sobrenome</label>
                    <input type="text" class="form-control sombra" v-model.trim="contacts.sobrenome" id="surname-form" name="surname"
                         placeholder="Digite seu sobrenome " required pattern="[A-Za-z]{}" title="Utilize apenas letras!">
        
                </div>

                <div class="mb-2 col-md-4 label">
                    <label for="empresa-form" class="form-empresa">Empresa</label>
                    <input type="text" class="form-control sombra" v-model.trim="contacts.empresa" name="company" id="empresa-form" placeholder="Digite o nome da empresa " required style="margin-top:8px;">
        
                </div>
                <div class="mb-2 col-md-8 label">
                    <label for="email-form" class="form-label">Email</label>
                    <input type="email" class="form-control sombra" v-model.trim="contacts.email" id="email-form" name="email" aria-describedby="emailHelp" 
                            placeholder="Ex: contacts@contato.com" required pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$" title="Adicone um valor de email válido!">
                    <div id="emailHelp" class="form-text">Nunca iremos compartilhar seu email com ninguém</div>
                </div>
                <div class="mb-2 col-md-4 label">
                    <label for="telefone-form" class="form-telefone">Telefone</label>
                    <input type="tel" class="form-control sombra" v-model.trim="contacts.telefone" name="cellphone" id="telefone-form" placeholder=" +55 27 99999-9999 " required maxlength="15" pattern="[0-9]{11}" title="Utilize apenas numeros">
        
                </div>
                <div class="mb-2 col-md-4 label">
                    <label for="cidade-form" class="form-cidade">Cidade</label>
                    <input type="text" class="form-control sombra" v-model.trim="contacts.cidade" id="cidade-form" placeholder=" Ex: Vitoria " required pattern="[A-Za-z]{}">
        
                </div>
                <div class="mb-3 col-md-4 label">
                    <label for="form-select" class="form-cidade">Estado</label>
                    <select class="form-select" v-model.trim="contacts.estado" required >
                        <option value="AC">Acre</option>
                        <option value="AL">Alagoas</option>
                        <option value="AP">Amapá</option>
                        <option value="AM">Amazonas</option>
                        <option value="BA">Bahia</option>
                        <option value="CE">Ceará</option>
                        <option value="ES">Espírito Santo</option>
                        <option value="GO">Goiás</option>
                        <option value="MA">Maranhão</option>
                        <option value="MT">Mato Grosso</option>
                        <option value="MS">Mato Grosso do Sul</option>
                        <option value="MG">Minas Gerais</option>
                        <option value="PA">Pará</option>
                        <option value="PB">Paraíba</option>
                        <option value="PR">Paraná</option>
                        <option value="PE">Pernambuco</option>
                        <option value="PI">Piauí</option>
                        <option value="RJ">Rio de Janeiro</option>
                        <option value="RN">Rio Grande do Norte</option>
                        <option value="RS">Rio Grande do Sul</option>
                        <option value="RO">Rondonia</option>
                        <option value="RR">Roraima</option>
                        <option value="SC">Santa Catarina</option>
                        <option value="SP">São Paulo</option>
                        <option value="SE">Sergipe</option>
                        <option value="TO">Tocantins</option>
                        <option value="DF">Distrito Federal</option>
                    </select>
                </div>

                <div class="mb-3 col-md-6 label">
                    <label for="form-select" class="form-assunto">Assunto:</label>
                    <select class="form-select" id="options" v-model.trim="contacts.assunto" name="subject" required>
                        <option value="COTACAO">Cotação</option>
                        <option value="DUVIDAS">Dúvidas</option>
                        <option value="SUGESTAO">Sugestão</option>
                        <option value="RECLAMACAO">Reclamação</option>
                        <option value="VENDAS">Vendas</option>  
                    </select>
                </div>

                <div class="mb-2 label">
                    <label for="texto-form" class="form-texto">Mensagem</label>
                    <textarea type="text" class="form-control sombra" v-model.trim="contacts.mensagem" name="message" id="texto-form" placeholder="Escreva-nos sua mensagem" required></textarea>
                </div>

                <div class="botao mb-4 mt-4">
                <button type="submit" @click="save"  v-if="mode == 'save'" class="btn btn-primary btn-send">Enviar</button>
                <button type="reset"  class="btn btn-danger" value="Reset">Excluir</button>
                </div>     
            </form>  
        </div> 
        
        <div class="linha-contatos">
            <span>VENDAS</span><br>
            <span>vendas@embali.com.br | +55 27 4009-3832</span>
        </div>
        <div class="linha-contatos">
            <span>SAC</span><br>
            <span >sac@embali.com.br | +55 27 4009-3849 </span>
        </div>

        <!-- RESPONSIVO -->
        <div class="linha-contatos-02">
            <span>VENDAS</span><br>
            <span>vendas@embali.com.br <br> +55 27 4009-3832</span>
        </div>
        <div class="linha-contatos-02">
            <span>SAC</span><br>
            <span >sac@embali.com.br <br> +55 27 4009-3849 </span>
        </div>

        <Rodape/>
    </div>
</template>

<script>
import Contato from '../components/Header-Contacts.vue'
import Rodape from '../components/Footer.vue'

import axios from 'axios'
import Swal from 'sweetalert2'

import emailjs from 'emailjs-com'
import { baseApiUrl } from '../global'

export default {
    name: 'Contact',
    components: {
        Contato,
        Rodape
    },
    data: function(){
        return {
            mode: 'save',
            contacts: {},
            contato: [],
            form: {
                
            }
        }
        
    },

    methods: {
        validatorForm(){
           

        },

        save() {
             axios.post(`${baseApiUrl}/contato`, this.contacts)
                .then(() => {
                    
                })
                .catch(function (error) {
                    console.log(error)
                })        
        },

        sendEmail () {
           emailjs.sendForm('service_ngync7a', 'template_46npetg', this.$refs.form, 'user_lDDq247rFJtTe4XMjPoSD')
                .then((result) => {
                   console.log('SUCCESS!', result.status, result.text);
                    if(result.status == 200){
                        Swal.fire({
                            position: 'center',
                            icon: 'success',
                            title: 'Seu formulario foi enviado com sucesso!',
                            showConfirmButton: false,
                            timer: 1500
                            })
                            setTimeout(function () { location.reload()}, 1000);
                    } else {
                        Swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: 'Aconteceu um erro ao enviar seu email!',
                            timer: 1500,
                            //footer: '<a href="">Why do I have this issue?</a>'
                            })
                            setTimeout(function () { location.reload()}, 1000);
                    }
                },(error) => {
                    alert('FAILED...', error);
               });
          },

            
        
    }

}
</script>

<style scoped>
    /* The code below should be used if you want to remove the blue shadow that boostrap automatically implements */
    .form-control:focus, .form-select:focus {
        border-color: #cccccc;
        outline: 0;
        -webkit-box-shadow: none;
            box-shadow: none;
    }

    ::-webkit-input-placeholder {
        font-size: 11pt;
    }

    #texto-form {
        height: 120px;
    }
    .formulario {
        display: flex;
        margin: 1% 15% 0 15%;
        padding: 10px;
        
    }
    .sombra:hover { background-color:  rgb(235, 232, 232);}

    #emailHelp, .botao{
        text-align: start;
    }
    .btn-send {
        margin-right: 5px;
    }

    .main-room {
        background-color: rgb(243, 243, 243);
    }


    .linha-contatos {
        background-color: rgb(126, 155, 228);
        height: 100px;
        font-size: 17pt;
        font-weight: 200;
        text-align: center;
        padding: 13px;
    }
    .linha-contatos-02 {
        background-color: rgb(214, 218, 228);
        height: 120px;
        font-size: 17pt;
        font-weight: 200;
        text-align: center;
        padding: 11px;
        overflow-y: hidden;
    }


    .legenda-contato {
        text-align: center;
    }

    .H1 {
        margin-top: 15px;
        font-size: 27pt;
    }

    .H2 {
        font-size: 15pt;
        font-weight: 200;
    }

    @media only screen and (max-width: 536px) {
        .linha-contatos {
            display: none;
        }

    }
    @media only screen and (min-width: 537px) {
        .linha-contatos-02 {
            display: none;
        }

    }

    .label {
        text-align: start;
    }
    
</style>
