<template>
  <div class="home">
    <HeaderHome/>
    <Main/>
    <Rodape/>
  </div>
</template>

<script>
// @ is an alias to /src
import HeaderHome from '@/components/Header-Home.vue'
import Main from '../components/Main.vue'
import Rodape from '../components/Footer.vue'

export default {
  name: 'Home',
  components: {
    HeaderHome,
    Main,
    Rodape
  }
}
</script>
