<template>
    <div>
        <Produto title="Embalagens" subtitle="Alta qualidade e diversidade"/>

        
        <!-- start of carrousel -->

        <div class="pictures">
          <div id="carouselExampleCaptions" class="carousel slide carousel-fade" data-bs-ride="carousel">
            <div class="carousel-indicators">
              <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
              <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="1" aria-label="Slide 2"></button>
              <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="2" aria-label="Slide 3"></button>
              <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="3" aria-label="Slide 4"></button>
              <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="4" aria-label="Slide 5"></button>
              <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="5" aria-label="Slide 6"></button>
            </div>

              <div class="carousel-inner" >
                <div class="carousel-item active" data-bs-interval="2000">
                  <img src="../assets/products/cosmetico.jpg" class="d-block w-100" alt="Linha Cosmético" style="border-radius:50px">
                  <div class="carousel-caption d-none d-md-block">
                    <h5 class="txt-foto">Linha Cosmético</h5>
                  </div>
                </div>
                <div class="carousel-item" data-bs-interval="2000">
                  <img src="../assets/products/farmaceutico.jpg" class="d-block w-100" alt="..." style="border-radius:50px">
                  <div class="carousel-caption d-none d-md-block">
                    <h5 class="txt-foto">Linha Farmacêutica</h5>
                    
                  </div>
                </div>
                <div class="carousel-item" data-bs-interval="2000">
                  <img src="../assets/products/alimenticio.jpg" class="d-block w-100" alt="..." style="border-radius:50px">
                  <div class="carousel-caption d-none d-md-block">
                    <h5 class="txt-foto">Linha Alimentícia</h5>
                    
                  </div>
                </div>

                <div class="carousel-item" data-bs-interval="2000">
                  <img src="../assets/products/pet.jpg" class="d-block w-100" alt="..." style="border-radius:50px">
                  <div class="carousel-caption d-none d-md-block">
                    <h5 class="txt-foto">Linha PET</h5>
                    
                  </div>
                </div>

                <div class="carousel-item" data-bs-interval="2000">
                  <img src="../assets/products/laboratorial.jpg" class="d-block w-100" alt="..." style="border-radius:50px">
                  <div class="carousel-caption d-none d-md-block">
                    <h5 class="txt-foto">Linha Laboratorial</h5>
                    
                  </div>
                </div>

                <div class="carousel-item" data-bs-interval="2000">
                  <img src="../assets/products/bottleCap.jpg" class="d-block w-100" alt="..." style="border-radius:50px">
                  <div class="carousel-caption d-none d-md-block">
                    <h5 class="txt-foto">Tampas</h5>
                    
                  </div>
                </div>
    

                </div>
                  <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
                    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span class="visually-hidden">Previous</span>
                  </button>
                  <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                    <span class="visually-hidden">Next</span>
                  </button>
                </div>
        </div>
     <!-- End of carrousel -->

        <div class="catalogue">
              
              <div class="card cartao-pdf">
                <div class="row g-0">
                  <div class="col-md-6">
                    <a :href="`${publicPath}Catalogo_Embali.pdf`" download="Catalogo Embali">
                      <img src="../assets/capa.jpg" class="img-fluid capa-pdf" alt="...">
                    </a>
                 
                  </div>
                  <div class="col-md-6">
                  <div class="card-body cartao-texto">
                    <h5 class="card-title" style="text-align:center">Catálogo Embali</h5>
                    <p class="card-text linha-catalogo" style="text-align: justify">Clique na imagem ao lado para fazer o download do nosso catálogo e conhecer mais sobre a empresa, locais de atuação, nossa produção e muito mais. </p>
                    <p class="card-text linha-catalogo-mobile" style="text-align: justify">Clique na imagem acima para fazer o download do nosso catálogo e conhecer mais sobre a empresa, locais de atuação, nossa produção e muito mais. </p>
                  </div>
                </div>
              </div>
          </div>
              
        </div>

        <div class="img-contatos">
            <img src="../assets/img-atendimento.jpg" class="img-fluid frota" alt="" srcset="">
        </div>
        <Rodape/>
    </div>
</template>

<script>
import Produto from '../components/Header-Products.vue'
import Rodape from '../components/Footer.vue'
export default {
    name: 'Products',
    components: {
        Produto,
        Rodape
    },
    data () {
      return {
        publicPath: process.env.BASE_URL,
        }
    },
    
}
</script>

<style scoped>

.cartao-pdf {
  max-width: 100%;
  margin: 0 10%;
  border: none;
}

.capa-pdf {
  border-radius: 30px;
  padding: 3px 0;
  
}

.txt-foto {
  color: black;
  font-size: 20pt;
}

.catalogue {
  margin-bottom: 10px;
}

.pictures {
    margin: 20px 8%;
}

@media only screen and (min-width: 756px) {
    .linha-catalogo-mobile {
        display: none;
    }
}
@media only screen and (max-width: 755px) {
    .linha-catalogo {
        display: none;
    }
}


.img-contatos {
        text-align: center;
        background-color: white;
        margin: 20px 10%;
    }

    .frota {
        width: 850px;
    }

</style>