<template>
  <div class="header">
       
       <nav class="navbar">

                <a class="navbar-brand" href="/">
                    <img src="../assets/logo.png" class="logo" alt="logo-embali" width="130" height="34" >
                </a>

                <!-- This code represents the mobile menu -->
                <div class="menu-icon">
                    <b-button v-b-toggle.sidebar-variant class="menu-text" href=""><img class="set-svg" src="../assets/icons/list.svg" alt="" srcset="">MENU</b-button>
                    <b-sidebar id="sidebar-variant" title="Menu" bg-variant="light" text-variant="black" shadow>
                        <div class="px-5 py-4">
                            <div class="header-mobile">
                                <router-link to="/" class="nav-link">Home</router-link>
                                <router-link to="/SobreNos" class="nav-link">Sobre Nós</router-link>
                                <router-link to="/Produtos" class="nav-link">Produtos</router-link>
                                <router-link to="/Contato" class="nav-link">Contato</router-link>
                                <router-link to="/TrabalheConosco" class="nav-link">Trabalhe Conosco</router-link>
                            </div>
                        </div>
                    </b-sidebar>
                </div>
                <!-- End mobile menu code  -->


                    <ul class="nav ">
                        <li class="nav-item">
                            <router-link to="/" class="nav-link">Home</router-link>
                        </li>
                        <li class="nav-item">
                            <router-link to="/SobreNos" class="nav-link">Sobre Nós</router-link>
                        </li>
                        <li class="nav-item">
                            <router-link to="/Produtos" class="nav-link">Produtos</router-link>
                        </li>
                        <li class="nav-item">
                            <router-link to="/Contato" class="nav-link">Contato</router-link>
                        </li>
                        <li class="nav-item">
                            <router-link to="/TrabalheConosco" class="nav-link">Trabalhe Conosco</router-link>
                        </li>
                        <!-- A rota abaixo se encontra desativada por motivos de desenvolvimento -->
                        <li class="nav-item">
                            <span class="nav-link" style="text-decoration:none; color: black;">{{ number }}</span>
                        </li>
                    </ul>
                
            <div class="legenda legenda-mobile">
                <h1 class="titulo">Trabalhe Conosco</h1>
            </div>
            <div class="legenda">
                <h2 class="subtitulo">Venha fazer parte da equipe</h2>
            </div>
                
                
        </nav>
   </div>
</template>

<script>
export default {
  name: 'Home',
  components: {
    
  },
  computed: {
      number(){
          return this.$store.state.number
      }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/* Configuração Logo\Imagem */
    .navbar {
        padding-bottom: 60px ;
        width: 100%;
        

        background-image: url(../assets/bg-header/workWithUs.jpg);
        background-color: rgb(255, 255, 255);
        background-position: center;
        background-repeat: no-repeat;
        /* background-size: 100%; */

        border-end-end-radius: 30px;
        border-end-start-radius: 30px;

        border-style: solid;
        border-bottom-width: 10px;
        border-top-width: 0;
        border-right-width: 0;
        border-left-width: 0;

    }
    .navbar-brand{
        margin-left: 70px;
        margin-top: -40px;
    }
    .logo {
        margin-top: 40px;
        width: 150px;
    }
    

    /* Configuração da nav-options */

    .nav {
       justify-content: baseline;
       text-decoration: none;
       margin-right: 3%;
       padding-top: 20px;  
    }
    .nav-link {
        text-decoration: none;
        color:black;
        font-family: Arial, sans-serif;
        font-size: 15pt;
    }

    .nav-item {
       margin-left: 5px;
       border-radius: 10px;
    }

    /* Modelagem legenda */
    .legenda {
        justify-content: center;
        align-items: center;
        margin: auto;
        width: 100%;    
    }
    .titulo {
        color: black;
        font-weight: 400;
        font-size: 60pt;
        padding-top: 40px;
        text-align: center;   
    }
    .subtitulo {
        color: black;
        font-size: 27pt;
        font-weight: 200;
        text-align: center;
    }
    .nav-link:hover { 
        color: rgb(194, 34, 34);
        text-decoration-line: underline;
        
        
    }

    /* ASIDE */






    /* responsividade */

    @media only screen and (max-width: 1052px) {
        * {
            overflow-x: hidden;
        }
        
        .titulo {
        font-weight: 400;
        font-size: 38pt;
        padding-top: 0px;
        text-align: center;   
        }
        .subtitulo, .subtitulo2 {
        font-size: 17pt;
        font-weight: 200;
        text-align: center;
        /* Caso queira o subtitulo quebrado basta decomentar o codigo abaixo */
        /* padding-left: 1px;
        padding-right: 1px; */
        }
        .nav {
            display: none;
        }
        .logo {
            margin-top: 20px;
        }
        .navbar {
            padding-top: 30px;
            padding-bottom: 30px;
            width: 100%;
        }
        .navbar-brand {
            margin-left: 20px;
        }

        .legenda-mobile {
            margin: 0 auto;
        }

        .set-svg {
            height: 23px;
            padding-top: 1px;
            margin-top: -4px;
            
        }
        .menu-text {
            text-decoration: none;
            color: black;
            font-size: 12pt;
            border: none;
            background-color: rgb(202, 202, 202);
            
        }
        .menu-text:hover {
            color: black;
            background-color: rgb(202, 202, 202);
            
        }
        .menu-icon {
            margin-right: 10px;
        }
        
    }

    /* TESTE DROPDOWN */
    @media only screen and (min-width: 1052px) {
        .img-drop {
            display: none;
        }

        .subtitulo2 {
            display: none;
        }
        
        .menu-icon {
            display: none;
        }
    }

    .img-drop {
        margin-right: 20px;
    }

    @media only screen and (max-width: 600px) {
        .navbar {
            background-image: url(../assets/bg-header-mobile/img-trab-mobile.jpg);
        }
    }

    .header {
        border-end-end-radius: 30px;
        border-end-start-radius: 30px;
    }
</style>
