<template>
    <div class="efeito">
        <footer class="rodape"> 
            <p class="frase">27 4002-3832 <br><br>Embali Industria Plasticas LTDA <br> Rua Das Hortencias 12, Cariacica | ES </p>
            
    <!-- Para telas maiores -->
            <div class="redes">
                <a class="face" href="https://www.facebook.com/embali.sa/" target="_blank"><img class="icon-f"  src="../assets/icons/facebook.png" alt="logoface" style="backgound-color:white;"></a>
                <a class="insta" href="https://www.instagram.com/embaliembalagens/" target="_blank"><img class="icon-i" src="../assets/icons/instagram.png" alt="logoinsta"></a>
                <a class="linkedin" href="https://www.linkedin.com/company/embali-s-a" target="_blank"><img class="icon-l"  src="../assets/icons/linkedin.png" alt="logolinkedin"></a>
               
            </div>
    <!-- Para telas mobile -->
            <div class="redes-mobile">
                <a class="face" href="https://www.facebook.com/embali.sa/" target="_blank"><img class="icon-f"  src="../assets/icons/facebook.png" alt="logoface"></a>
                <a class="insta" href="https://www.instagram.com/embaliembalagens/" target="_blank"><img class="icon-i" src="../assets/icons/instagram.png" alt="logoinsta"></a>
                <a class="linkedin" href="https://www.linkedin.com/company/embali-s-a" target="_blank"><img class="icon-l"  src="../assets/icons/linkedin.png" alt="logolinkedin"></a>
            </div>
        </footer>
    </div>
</template>

<script>
export default {

}
</script>

<style scoped>
    .rodape{
        display: flex;
        text-align: center;
        justify-content: center;
    }

    .efeito{
        background-color: rgb(221, 39, 39);
        overflow-x: hidden;
    
    }
    .frase{
        color: white;
        font-weight: 400;
        font-size: 11pt;
        padding-top: 10px;
    }
    .redes {
        display: flex;
        margin-top: 2%;

    }
    .face { 
        margin-right: 10px;
        
    }
    .icon-l {
        margin-left: 10px;
    }
    .icon-f , .icon-i, .icon-l{
        width: 25px;
    }

    /* responsividade */
    @media only screen and (max-width: 1052px) {
        .redes {
            display: none;
        }
        .rodape {
        display: block;
        text-align: center;
        justify-content: center;
        }
        .face {
            margin-left: 0px;
        }
        .redes-mobile {
           margin-bottom: 20px;
        }
        .icon-f, .icon-l, .icon-i {
            margin-top: -20px;
        }
        
    }

    @media only screen and (min-width: 1052px) {
        .face {
            margin-left: 400%;
        }

        .redes {
            margin-top: 40px;
        }
        .redes-mobile {
            display: none;
        }
    }

</style>