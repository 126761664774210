<template>
    <div class="centered">

       <div >
 
            <section class="cards flex" v-for="box in embaliBox" :key="box.id">

                <div class="card-1 item" >
                   <h1 class="estilo-card">{{ box.title }}</h1>
                   <p class="estilo-phara">{{ box.subject }}</p>    
                </div>
 
            </section>
        </div>


    </div>
</template>

<script>
export default {
    name: 'Principal',
    data() { 
        return {
            embaliBox: [{
            title: "Sobre Nós",
            subject: "A Embali conta com o mais avançado sistema de produção e com profissionais altamente qualificados, o que possibilita sua incrível capacidade de produção, agilidade nos prazos, flexibilidade de negociação e ampla vantagem tecnológica nos segmentos em que atua.",
        }, {
            title: "Produtos",
            subject: "Fabricadas em polietileno e polipropileno, as embalagens Embali contam com alta tecnologia de produção e se destacam pela qualidade. A variedade de produtos contempla diversos setores da indústria. Confira nosso catálogo de embalagens.",
        }, {
            title: "Contato",
            subject: "Produtos diferenciados e atendimento eficiente. Acreditamos nessa fórmula para garantir a satisfação de nossos clientes. Se você está entre eles, este é o seu espaço. Envie sua mensagem e contribua para que a Embali seja cada vez melhor.",
        }, {
            title: "Trabalhe Conosco",
            subject: "Aqui na Embali temos um time de profissionais de diferentes áreas, mas com um mesmo objetivo: fazer sempre o melhor que podemos. Se você se identifica com a nossa filosofia, venha fazer parte da equipe.",
        }]
        }
        
    }
}
</script>

<style scoped>
    .flex {
        flex-wrap: wrap;
    }
    .cards {
        margin-top: 30px;
        margin-bottom: 15px;
        margin-right: 15%;
        margin-left: 15%;
        display: flex;
    }
        
    .estilo-card{
        font-size: 23pt;
        font-weight: 400;
        margin-top: 15px;
        text-align: center;
    }
    .estilo-phara{
        font-weight: 100;
        font-size: 15pt;
        text-align: center;
        margin: 10px 5px;
    }

    .item {
        margin: 5px;
        background: rgb(250, 250, 250);
        position: relative;
        text-align: left;
    }
    

    @media only screen and (max-width: 1052px) {
        .cards {
            margin: 5% 2%;
            display: flex;
    }
    }

    

</style>