<template>
    <div>
        <div class="error">
             4 0 4
        </div>
        <div class="phrase-error">
            Sorry, the page you're looking could not be found :(
        </div>
        
    </div>
</template>

<script>
export default {

}
</script>

<style scoped>
 .error {
    font-size: 120pt;
 }
 .phrase-error {
     font-size: 30pt;
 }
</style>