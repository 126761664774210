import Vue from 'vue'
import App from './App.vue'
import router from './router'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import store from '../src/store/store'

import Vuelidate from 'vuelidate'


//Chat bot
import Tawk from 'vue-tawk'

Vue.use(BootstrapVue)

Vue.use(Tawk, {
  tawkSrc: 'https://embed.tawk.to/59a05152b6e907673de099cd/default'
})
Vue.use(Vuelidate)


Vue.config.productionTip = false

new Vue({
  router,
  BootstrapVue,
  IconsPlugin,
  store,
  
  render: h => h(App)
}).$mount('#app')
