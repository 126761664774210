<template>
    <div>
        <HeaderWork/>
        <!-- Formulario -->
        <div class="titulo">
            <h1> Transformando Pessoas </h1>
            <p>Aqui na Embali temos um time de profissionais de diferentes áreas, mas com um mesmo objetivo:<br> 
            fazer sempre o melhor que podemos. Se você se identifica com a nossa filosofia, venha fazer parte da equipe.</p>
        </div>
        <div class="formulario">
             <form ref="form" class="conteudo row" action="/TrabalheConosco" method="" @submit.prevent="mailWork">
                <div class="mb-2 col-md-6 h1-form">
                    <label for="nome-form" class="form-name label">Nome</label>
                    <input type="text" class="form-control sombra" id="nome-form" v-model="trabConosco.nome" placeholder="Digite seu nome " 
                        name="name" required pattern="[A-Za-z]{}" title="O nome não pode conter números!"> 
        
                </div>
                <div class="mb-2 col-md-6 h1-form">
                    <label for="surname-form" class="form-surname">Sobrenome</label>
                    <input type="text" class="form-control sombra" id="surname-form" v-model="trabConosco.sobrenome" placeholder="Digite seu nome " 
                        name="surname" required pattern="[A-Za-z]{}" title="O sobrenome não pode conter números!">
        
                </div>

                <div class="mb-2 col-md-6 h1-form">
                    <label for="email-form" class="form-label">Email</label>
                    <input type="email" class="form-control sombra" id="email-form" name="email" v-model="trabConosco.email" aria-describedby="emailHelp" 
                        placeholder="Ex: contato@embali.com.br" required pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$" title="adicone um valor de email válido!">
                    <div id="emailHelp" class="form-text">Nunca iremos compartilhar seu email com ninguém</div>
                </div>


                <div class="mb-2 col-md-6 h1-form">
                    <label for="celular-form" class="form-celular" style="margin-top:8px">Telefone</label>
                    <input type="tel" class="form-control sombra" id="celular-form" name="cell" v-model="trabConosco.telefone" placeholder=" 27 9 9999-9999 " required maxlength="11" pattern="[0-9]{11}" title="Utilize apenas numeros">
                </div>

                <div class="mb-2 col-md-4 h1-form">
                    <label for="zipcode-form" class="form-zipcode">CEP</label>
                    <input type="text" class="form-control sombra" id="zipcode-form" name="zipcode" v-model="trabConosco.cep" placeholder="Ex: 00000-000" maxlength="8" required pattern="[0-9]{8}" title="Utilize apenas numeros">
                </div>
                <div class="mb-2 col-md-4 h1-form">
                    <label for="cidade-form" class="form-cidade">Cidade</label>
                    <input type="text" class="form-control sombra" id="cidade-form" name="city" v-model="trabConosco.cidade" placeholder=" Ex: Vitoria " 
                        required pattern="[A-Za-z]{}" title="O nome da cidade deve conter apenas letras!">
                </div>


                <div class="mb-3 col-md-4 h1-form">
                    <label for="form-select" class="form-cidade">Estado</label>
                    <select class="form-select" v-model="trabConosco.estado" name="state" required>
                        <option value="AC">Acre</option>
                        <option value="AL">Alagoas</option>
                        <option value="AP">Amapá</option>
                        <option value="AM">Amazonas</option>
                        <option value="BA">Bahia</option>
                        <option value="CE">Ceará</option>
                        <option value="ES">Espírito Santo</option>
                        <option value="GO">Goiás</option>
                        <option value="MA">Maranhão</option>
                        <option value="MT">Mato Grosso</option>
                        <option value="MS">Mato Grosso do Sul</option>
                        <option value="MG">Minas Gerais</option>
                        <option value="PA">Pará</option>
                        <option value="PB">Paraíba</option>
                        <option value="PR">Paraná</option>
                        <option value="PE">Pernambuco</option>
                        <option value="PI">Piauí</option>
                        <option value="RJ">Rio de Janeiro</option>
                        <option value="RN">Rio Grande do Norte</option>
                        <option value="RS">Rio Grande do Sul</option>
                        <option value="RO">Rondonia</option>
                        <option value="RR">Roraima</option>
                        <option value="SC">Santa Catarina</option>
                        <option value="SP">São Paulo</option>
                        <option value="SE">Sergipe</option>
                        <option value="TO">Tocantins</option>
                        <option value="DF">Distrito Federal</option>
                    </select>
                </div>


                
                <div class="mb-3 col-md-6 h1-form">
                    <label for="form-select" class="form-escolaridade">Escolaridade:</label>
                    <select class="form-select" name="scholling" v-model="trabConosco.escolaridade" required>
                        <option value="FI">Ensino Fundamental Incompleto</option>
                        <option value="FC">Ensino Fundamental Completo</option>
                        <option value="MI">Ensino Médio Incompleto</option>
                        <option value="MC">Ensino Médio Completo</option>
                        <option value="SI">Ensino Superior Incompleto</option>
                        <option value="SC">Ensino Superior Completo</option>
                        <option value="POS">Pós Graduação</option>
                    </select>
                </div>

                
                <div class="mb-3 col-md-6 h1-form">
                    <label for="form-select" class="form-cargo">Cargo:</label>
                    <select class="form-select" v-model="trabConosco.cargo" name="position" required>
                        <option value="ASG">Auxiliar de Sevicos Gerais</option>
                        <option value="ESTAGIARIO">Estagiário</option>
                        <option value="ADM">Setor Administrativo</option>
                        <option value="GERENTE">Gerente</option>
                        <option value="MOTORISTA">Motorista</option>
                        <option value="RH">Recursos Humanos</option>
                        <option value="REPRESENTANTE">Representante Comercial PJ</option>
                        <option value="VENDAS INT">Vendedor Interno</option>
                        <option value="VENDAS EXT">Vendedor Externo</option>
                        <option value="TI">TI(Tecnologia da Informacao)</option>
                    </select>
                </div>

                <!-- TEMPORARIAMENTE SEM NOME/ PESQUISAR MAIS SOBRE -->
                <!-- Por motivos de utilizar api externa não será utilizado o recebimento de arquivos -->
                <!-- <div class="mb-2 col-md-12">
                    <label for="curriculo-form" class="form-curriculo">Anexar Curriculo </label>
                    <input type="file" class="form-control" id="curriculo-form" name="resume" >
                </div> -->
                    <hr>
                <!-- Termo LGPD -->
                <div class="term">
                    <div class="texto-lgpd">
                        <strong> <p class="termo-phara">Prezado Candidato. Para que a Embali Indústrias Plásticas possa avaliar seu currículo, bem como possa dar andamento ao processo seletivo do qual você está participando, é necessário que seus dados pessoais sejam tratados pela empresa, a que envolve a coleta, a análise e o armazenamento dos dados pessoais constantes de seu currículo e demais dados pessoais que sejam por você fornecidos, bem como durante o período em que o processo seletivo estiver em andamento, alguns outros dados podem ser coletados por meio de video entrevista, teste psicológico, exames médicos, etc. Com o término do processo de seleção, os curriculos e dados pessoais de todos os candidatos não selecionados pela Embali, são eliminados definitivamente pela empresa. Independentemente do resultado do processo seletivo, à Embali não compartilhará seus dados pessoais com nenhum terceiro, bem como não os utilizará com outra finalidade diferente da informada neste documento. O Consentimento fornecido no presente formulário poderá ser revogado a qualquer momento, mediante solicitação expressa para rh@embali.com.br ou telefone (27) 4009-3832. Em conformidade com a Lei Geral de Proteção de Dados (Lei n 13.709/2018), caso voce tenha interesse em ser contratado para novas oportunidades de trabalho na Embali, por favor responda a pergunta presente no formulário: Você autoriza a Embali Indústrias Plásticas a armazenar seu currículo por tempo indeterminado, para integrar a base de currículos e possibilitar o contato caso surja uma nova vaga aderente a seu perfil profissional? </p></strong>
                    </div>
    
                    <div class="mb-2 marcador">
                        <input type="checkbox" v-model="terms" value="true" name="leituraTermo" id="terms" > Sim, autorizo a Embali a armazenar meu currículo com a finalidade 
                        de me informar sobre novas oportunidades aderentes ao meu perfil profissional.
                
                       
                    </div>
                    <!-- <div class="mb-4">
                        <input type="radio" v-model="noterms" value="false" name="leituraTermo" id="noterms" >
                        <label for="noterms">&nbsp; Não, eu não autorizo a Embali a armazenar meu currículo com a finalidade 
                    de me informar sobre novas oportunidades aderentes ao meu perfil profissional.</label>
                        
                    </div> -->
                    <div style="text-align:start">
                        <button type="submit" @click="save()" v-if="mode == 'save'" :disabled='isDisabled'  class="btn btn-success mb-3" name="confirmar" id="button" >Enviar</button>
                    </div>
                    
                </div>
            </form>
        <div/>
    </div>
        <Rodape/>
    </div>
</template>

<script>
import HeaderWork from '../components/Header-Work.vue'
import Rodape from '../components/Footer.vue'

import axios from 'axios'
import emailjs from 'emailjs-com'
import Swal from 'sweetalert2'
import { baseApiUrl } from '../global'

export default {
    name: 'Work',
    components: {
        HeaderWork,
        Rodape
    },
    data: function(){
        return {
            mode: 'save',
            trabConosco: {},
            curriculos: [],
            terms: false
        }
    },
    computed: {
        isDisabled: function(){
            return !this.terms
        }
    },

    methods: {
        save() {
             axios.post(`${baseApiUrl}/TrabalheConosco`, this.trabConosco)
                .then(() => {
                    
                })
                .catch(e => {
                    console.log(e)
                })
        },

       mailWork(){
           emailjs.sendForm('service_545dbhq', 'template_5whovx9', this.$refs.form, 'user_aFZDIjOm5xrwcBfySRDYS')
                .then((result) => {
                   console.log('SUCCESS!', result.status, result.text);
                    if(result.status == 200){
                        Swal.fire({
                            position: 'center',
                            icon: 'success',
                            title: 'Seu formulario foi enviado com sucesso!',
                            showConfirmButton: false,
                            timer: 1500
                            })
                            setTimeout(function () { location.reload()}, 1000);
                    } else {
                        Swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: 'Aconteceu um erro ao enviar seu email!',
                            timer: 1500,
                            //footer: '<a href="">Why do I have this issue?</a>'
                            })
                            setTimeout(function () { location.reload()}, 1000);
                    }
                }, (error) => {
                    console.log('FAILED...', error);
               });
    
          },

          
    }
}
</script>

<style scoped>
/* The code below should be used if you want to remove the blue shadow that boostrap automatically implements */
    .form-control:focus, .form-select:focus {
        border-color: #cccccc;
        outline: 0;
        -webkit-box-shadow: none;
            box-shadow: none;
    }

    .h1-form {
        text-align: start;
    }

    .titulo {
        margin-top: 40px;
    }

    h1 {
        text-align: center;
        font-weight: 300;
    }
    p {
        font-size: 15pt;
        text-align: center;
    }
    .sombra:hover { background-color:  rgb(241, 241, 241);}

    .termo-phara {
        font-weight: 400;
        font-size: 13pt;
        text-align: justify;
    }

    .formulario {
        margin: 0 10%;
    }

    .marcador {
        text-align: justify;
    }

</style>