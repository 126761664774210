<template>
  <div>
    <Sobre/>
    <div class="parte1" style="justify-content:center;">
            <p class="text1"> &nbsp;&nbsp;&nbsp; A Embali nasceu do sonho de uma família simples do interior com muita garra e determinação que construiu uma história de sucesso. &nbsp; <br> Em 1995 surgiu a oportunidade e começaram a atuar no mercado de embalagens plásticas em polietileno e polipropileno de alta qualidade.
            </p>
        </div>
        <div class="picture">
            <!-- Imagem para dispositivos maiores -->
            <img class="oldCompany img-fluid" src="../assets/fachada-embali.jpg" alt="Embali Antiga">
            <!-- Imagem para dispositivos menores -->
            <img class="oldCompany-mobile img-fluid" src="../assets/fachada-embali.jpg" alt="Embali Antiga">    
        </div>

        <div class="parte2">
            <p class="text2"> &nbsp;&nbsp;&nbsp; Sempre preocupada em conquistar cada dia mais a confiança e a satisfação de nossos clientes
            e parceiros, investimos em equipamentos de alta tecnologia, promovendo a capacitação contínua de nossos colaboradores 
            e aprimorando o sistema de logística. E dessa forma, hoje a Embali esta presente em todo território nacional,
            crescendo e continuando o processo de conquista de mercado. Hoje com um parque industrial, localizado na cidade de Cariacica-ES (região metropolitana da Grande Vitória), 
            possuímos uma área de 10 mil metros quadrados, que comporta equipamentos modernos acompanhando as tendências do setor. 
            Em 2011 a Embali conseguiu seu certificado da ISO 9001, na qual vem mantendo seus padrões e sendo recerticada no anos atuais confirmando seu trabalho organizado e coerente com os pré-requisitos que são necessários.
            </p>  
        </div>
        
        <div class="centralizado">
 
            <section class="container basis-numero-container">
                 
                <div class="card-flex-1 item bg-01 bg-05 bg-09 bg-13">
                   <h1 class="estilo-card">Missão</h1>
                   <p class="estilo-phara">Atuar no setor de embalagens plásticas no seguimento de sopro e injeção com padrões de excelência na fabricação e atendimento, proporcionando retorno justo aos acionistas, valorizando os colaboradores e trazendo satisfação ao cliente, exercendo assim responsabilidade social e corporativa.</p>
                   
                </div><!-- /card-one -->
 
                <div class="card-flex-2 item bg-02 bg-06 bg-10 bg-14">
                   <h1 class="estilo-card item" style="background-color:transparent;">Visão</h1>
                   <p class="estilo-phara" >Ser reconhecida até 2025 e se manter a partir de então como referência no mercado nacional de embalagens plásticas como melhor opção pelos clientes focando em:<br>
                        - Valor justo.<br>
                        - Fortalecimento no relacionamento.<br>
                        - Criação de valor para a empresa.<br>
                        - Sustentabilidade dos negócios.<br>
                        - Tornando-se assim importantes na vida de parceiros comerciais, colaboradores e da comunidade.</p>
                </div><!-- /card-two -->
   
                <div class="card-flex-3 item bg-03 bg-07 bg-11 bg-15">
                  <h1 class="estilo-card">Valores</h1>
                  <p class="estilo-phara">
                    - Eficiente com ética (Maximização dos recursos com ética)<br>
                    - Maquinaria e mão de obra (Equipamentos eficientes e atualizados operados por pessoal qualificado)<br>
                    - Busca constante do aperfeiçoamento (Negócios, processos, produtos, serviços e pessoal em constante aprimoramento)<br>
                    - Alerta e com ambição (Atento às tendências do mercado com o objetivo de chegar à frente das oportunidades)<br>
                    - Local com pensamento global (Trabalhar com visão do mercado nacional absorvendo as melhores praticas apontadas no mundo)<br>
                    - Inovadora com iniciativa (Antecipar novos produtos, serviços e desenhos que satisfaçam a necessidade do mercado)</p>
                </div><!-- /card-three -->

                <div class="card-flex-3 item bg-04 bg-08 bg-12 bg-16">
                  <h1 class="estilo-card">Política de Qualidade Embali</h1>
                  <p class="estilo-phara" >
                    A Embali Indústrias Plásticas adota como política:<br>
                    - Atender os requisitos legais aplicáveis.<br>
                    - Implementação de melhoria contínua através de um sistema de gestão de qualidade, eficaz, capaz de atender e por vezes superar as expectativas e necessidades de seus clientes.<br>
                    - Proporcionar um retorno justo para o capital bem como promover o desenvolvimento e envolvimento de pessoas.<br>
                    </p>
                </div><!-- /card-four -->
 
            </section>
        </div>
    <Rodape/>
  </div>
</template>

<script>
import Sobre from '../components/Header-About.vue'
import Rodape from '../components/Footer.vue'
export default {
    name: 'About',
    components: {
      Sobre,
      Rodape
    }
}
</script>

<style scoped>
  /* primeira descricao */
    .text1 {
        font-size: 14pt;
        text-align: justify;
        padding: 2% 10% 1% 10%;
    }
    .text2 {
       width: 100%;
       height: 100%; 
       font-size: 14pt;
       text-align: justify;
       padding: 2% 10% 1% 10%;
    }
    
    .parte1, .parte2 {
        display: flex;
        background-color: white;
        align-items: center;
    }

/* image style */
    .picture {
        height: 250px;
        text-align: center;
        padding-top: 15px ;
    }
    .oldCompany {
        border-radius: 20px;
    }

/* Valores */
    .basis-numero-container{
        flex-wrap: wrap;
    }
    .container {
        margin: 15px auto;
        display: flex;
    }
    .card-flex-1 {
        /* flex: grow, shrink, basis */
        flex: 1 1 25% ;
    }
    .card-flex-2 {
        /* flex: grow, shrink, basis */
        flex: 1 1 70%;
    }
    .card-flex-3 {
        /* flex: grow, shrink, basis */
        flex: 1 1 auto;
    }

    .item {
        margin: 5px;
        background:rgb(245, 245, 245);
        text-align: center;
        position: relative;
    }
    .estilo-card {
      padding-top: 10px;
      
    }
    .estilo-phara {
        font-size: 15pt;
        color: rgb(0, 0, 0);
    }

    /* RESPONSIVIDADE */

    @media only screen and (max-width: 1052px){ 
        .oldCompany {
            display: none;
    
        }
        .parte1, .parte2 {
            flex-direction: column;
        }

        .text2 { 
            order: 1;
            width: 100%;
            padding: 1% 4%;
            margin-top: 10px; 
            text-align: justify;   
        }

        .text1 {
            padding: 1% 1%;
            text-align: center;
        }
        .oldCompany-mobile {
            padding: 0 8px;
            border-radius: 20px;
        }
        .picture {
            height: 250px;
        }
    }

    @media only screen and (min-width: 1052px){ 
        .oldCompany-mobile {
            display: none;
        }
    }

    @media only screen and (min-width: 1400px){ 
    .bg-01 {
        background-image: url("../assets/bg-about/bg-px1400/bg-00.jpg");
    }
    .bg-02 {
        background-image: url("../assets/bg-about/bg-px1400/bg-01.jpg");
    }
    .bg-03 {
        background-image: url("../assets/bg-about/bg-px1400/bg-02.jpg");
    }
    .bg-04 {
        background-image: url("../assets/bg-about/bg-px1400/bg-03.jpg");
    }
    }

    @media only screen and (min-width: 1200px) and (max-width: 1399px){
    .bg-05 {
        background-image: url("../assets/bg-about/bg-px1399/bg-01.jpg");
    }
    .bg-06 {
        background-image: url("../assets/bg-about/bg-px1399/bg-02.jpg");
    }
    .bg-07 {
        background-image: url("../assets/bg-about/bg-px1399/bg-03.jpg");
    }
    .bg-08 {
        background-image: url("../assets/bg-about/bg-px1399/bg-04.jpg");
    }

    
    }

    @media only screen and (min-width: 992px) and (max-width: 1199px){
    .bg-09 {
        background-image: url("../assets/bg-about/bg-px975/photo-1.jpg");
    }
    .bg-10 {
        background-image: url("../assets/bg-about/bg-px975/photo-2.jpg");
    }
    .bg-11 {
        background-image: url("../assets/bg-about/bg-px975/photo-3.jpg");
    }
    .bg-12 {
        background-image: url("../assets/bg-about/bg-px975/photo-4.jpg");
    }

    
    }

    @media only screen and (min-width: 768px) and (max-width: 991.5px){
    .bg-13 {
        background-image: url("../assets/bg-about/bg-px755/photo-5.jpg");
    }
    .bg-14 {
        background-image: url("../assets/bg-about/bg-px755/photo-6.jpg");
    }
    .bg-15 {
        background-image: url("../assets/bg-about/bg-px755/photo-8.jpg");
    }
    .bg-16 {
        background-image: url("../assets/bg-about/bg-px755/photo-7.jpg");
    }

    
    }
</style>
